import { css } from 'styled-components'
import { ThemeContainer } from '../../theme/Theme'
import pixelsToRems from '../../theme/utils/pixelsToRems'

import { ContainerProps } from './Button'

const defaultButtonStyle = css<ContainerProps>`
  padding: ${({ theme }: ThemeContainer) =>
    `${pixelsToRems(13)} ${theme.spacing.medium} ${pixelsToRems(11)};`}
  background-color: var(--blue5);
  color: #ffffff;

  &:hover {
    background-color: var(--blue1);
  }
`

export default defaultButtonStyle

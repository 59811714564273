export const size = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export enum Devices {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

const device: Record<keyof typeof Devices, string> = {
  xsmall: `(max-width: ${size.sm - 0.02}px)`,
  small: `(min-width: ${size.sm}px) and (max-width: ${size.md - 0.02}px)`,
  medium: `(min-width: ${size.md}px) and (max-width: ${size.lg - 0.02}px)`,
  large: `(min-width: ${size.lg}px) and (max-width: ${size.xl - 0.02}px)`,
  xlarge: `(min-width: ${size.xl}px)`,
}

export default device

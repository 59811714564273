import {
  cloneElement,
  FunctionComponent,
  PropsWithChildren,
  useRef,
  ReactElement,
  JSXElementConstructor,
} from 'react'
import styled from 'styled-components'
import { ThemeContainer } from '../../../theme/Theme'
import pixelsToRems from '../../../theme/utils/pixelsToRems'
import Chevron from '../../../_components/icons/Chevron/Chevron'
import { useMobileMenu } from './MobileMenuContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

interface ContentProps extends ThemeContainer {
  isOpen: boolean
  contentHeight: string
}

const Content = styled.ul<ContentProps>`
  overflow: hidden;
  height: 0px;
  transition: height 0.3s var(--ease-in-quint);

  ${({ isOpen, contentHeight }) =>
    isOpen &&
    `
    height: ${contentHeight};
  `}
`

interface MenuLabelProps extends ThemeContainer {
  isOpen: boolean
}

const MenuLabel = styled.div<MenuLabelProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey2);
  padding: ${({ theme }: ThemeContainer) =>
    `${theme.spacing.small} ${theme.spacing.medium}`};
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.large};
  color: var(--text);
  text-transform: uppercase;

  svg {
    height: ${pixelsToRems(20)};
    transition: transform 0.3s var(--ease-in-quint);
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    color: var(--brand);

    svg {
      transform: scale(-1);
      transition: transform 0.3s var(--ease-out-quint);
    }
  `}
`

export interface Props extends PropsWithChildren {
  label: string
}

const MobileCollapsableMenu: FunctionComponent<Props> = ({
  label,
  children,
}) => {
  const childRef = useRef<HTMLUListElement>()
  const { openMenu, handleMenuToggle } = useMobileMenu()
  const isOpen = openMenu === label
  const contentHeight = childRef
    ? `${childRef?.current?.clientHeight}px`
    : '200px'

  return (
    <Container>
      <MenuLabel
        role="button"
        onClick={() => {
          handleMenuToggle(label)
        }}
        isOpen={isOpen}
      >
        <span>{label}</span>
        <Chevron />
      </MenuLabel>
      <Content
        isOpen={isOpen}
        contentHeight={contentHeight}
        className="mobile-collapsable-menu"
      >
        {cloneElement(
          children as ReactElement<any, string | JSXElementConstructor<any>>,
          { ref: childRef }
        )}
      </Content>
    </Container>
  )
}

export default MobileCollapsableMenu

import postExcerpt from '../../_components/PostExcerpt/intl/en'
import latestStories from '../../components/LatestStories/intl/en'
import homepage from '../../intl/pages/homepage/en'
import careers from '../../intl/pages/careers/en'
import blog from '../../intl/pages/blog/en'
import aboutUs from '../../intl/pages/about-us/en'
import caseStudyHeading from '../../components/CaseStudyHeading/intl/en'
import careerHeading from '../../components/CareerHeading/intl/en'
import contactPageForm from '../../components/ContactPageForm/intl/en'
import mailingListCtaSection from '../../_components/MailingListCTASection/intl/en'
import demoFormCtaSection from '../../components/DemoFormCTASection/intl/en'
import legalHeading from '../../components/LegalHeading/intl/en'
import postListSection from '../../components/PostListSection/intl/en'

import global from '../../intl/en'
import components from '../../_components/intl/en'

const messages = {
  global,
  components,
  postExcerpt,
  latestStories,
  homepage,
  careers,
  blog,
  aboutUs,
  caseStudyHeading,
  careerHeading,
  contactPageForm,
  mailingListCtaSection,
  demoFormCtaSection,
  legalHeading,
  postListSection,
}

export default messages

import { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { ThemeContainer } from '../../../theme/Theme'
import device, { size } from '../../../theme/utils/device'
import pixelsToRems from '../../../theme/utils/pixelsToRems'
import Bars from '../../../_components/icons/Bars/Bars'
import ReactPortal from '../../../_components/ReactPortal/ReactPortal'
import Close from '../../../_components/icons/Close/Close'
import useHeaderMobileMenu from '../hooks/useHeaderMobileMenu'
import { Link } from 'gatsby'
import MobileMenuContextProvider from './MobileMenuContext'
import MobileCollapsableMenu from './MobileCollapsableMenu'
import { FormattedMessage, useIntl } from 'react-intl'
import useHasPages from '../../../hooks/useHasPages'
import { useLocationContext } from '../../Layout/Layout'
import {
  BLOG_BASE_URL,
  CUSTOMERS_BASE_URL,
  PRESS_BASE_URL,
  PRODUCT_UPDATES_BASE_URL,
} from './Header'

const MenuButton = styled.button`
  display: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  margin-left: ${({ theme }: ThemeContainer) => theme.spacing.medium};
  cursor: pointer;

  @media (max-width: ${size.lg - 0.02}px) {
    display: inline-block;
  }

  & > svg {
    height: ${pixelsToRems(40)};
  }
`

const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  animation-duration: 0.5s;
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-timing-function: var(--ease-in-quint);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
  will-change: backdrop-filter, background-color;
  z-index: 10;

  @keyframes fade-in {
    0% {
      backdrop-filter: blur(0px);
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes fade-out {
    0% {
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      backdrop-filter: blur(0px);
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &.willClose {
    animation-duration: 0.3s;
    animation-name: fade-out;
    animation-iteration-count: 1;
    animation-timing-function: var(--ease-out-quint);
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
  }
`

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  max-width: 400px;
  background-color: #ffffff;
  will-change: right;
  box-sizing: border-box;
  z-index: 11;

  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-iteration-count: 1;

  @keyframes slide-in {
    0% {
      right: -400px;
    }
    100% {
      right: 0px;
    }
  }

  @keyframes slide-out {
    0% {
      right: 0px;
    }
    100% {
      right: -400px;
    }
  }

  &.willClose {
    animation-duration: 0.3s;
    animation-name: slide-out;
    animation-iteration-count: 1;
    animation-timing-function: var(--ease-in-quint);
    right: -400px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }: ThemeContainer) => theme.spacing.medium};
  right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  & > svg {
    height: ${pixelsToRems(40)};
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 130px;
  overflow: hidden;

  @media ${device.xsmall} {
    padding-top: 80px;
  }
`

const List = styled.ul`
  display: block;
  flex: 1;
`

const ListItem = styled.li`
  display: block;
`

const MenuLink = styled(Link)`
  display: block;
  padding: ${({ theme }: ThemeContainer) =>
    `${theme.spacing.small} ${theme.spacing.medium}`};
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.xlarge};
  text-decoration: none;
  border-bottom: 1px solid var(--grey2);
  color: var(--text);
`

const HeaderMobileMenu: FunctionComponent = () => {
  const intl = useIntl()
  const {
    selectors: {
      hasBlogPage,
      hasProductUpdatesPage,
      hasCustomersPage,
      hasPressPage,
    },
  } = useHasPages()
  const {
    selectors: { open, willClose },
    actions: { handleMenuOpen, handleMenuClose },
  } = useHeaderMobileMenu()
  const { pathname } = useLocationContext()

  useEffect(() => {
    handleMenuClose()
  }, [pathname])

  return (
    <>
      <MenuButton onClick={() => handleMenuOpen()} aria-label="Mobile menu">
        <Bars />
      </MenuButton>
      <ReactPortal>
        {open && (
          <Overlay
            className={classNames({
              willClose,
            })}
            onClick={handleMenuClose}
          />
        )}
        {open && (
          <Container
            className={classNames({
              willClose,
            })}
          >
            <CloseButton onClick={() => handleMenuClose()}>
              <Close />
            </CloseButton>
            <Content>
              <MobileMenuContextProvider>
                <MobileCollapsableMenu
                  label={intl.formatMessage({
                    id: 'global_menu_solutions_title',
                  })}
                >
                  <List role="menu">
                    <ListItem>
                      <MenuLink to="/brokerage/" role="menuitem">
                        <FormattedMessage id="global_menu_solutions_links_brokerage" />
                      </MenuLink>
                    </ListItem>
                    <ListItem>
                      <MenuLink to="/mortgage/" role="menuitem">
                        <FormattedMessage id="global_menu_solutions_links_mortgage" />
                      </MenuLink>
                    </ListItem>
                    <ListItem>
                      <MenuLink to="/relocation/" role="menuitem">
                        <FormattedMessage id="global_menu_solutions_links_relocation" />
                      </MenuLink>
                    </ListItem>
                    <ListItem>
                      <MenuLink to="/recruiting/" role="menuitem">
                        <FormattedMessage id="global_menu_solutions_links_recruiting" />
                      </MenuLink>
                    </ListItem>
                  </List>
                </MobileCollapsableMenu>
                <MobileCollapsableMenu
                  label={intl.formatMessage({
                    id: 'global_menu_resources_title',
                  })}
                >
                  <List role="menu">
                    {hasCustomersPage && (
                      <ListItem>
                        <MenuLink to={CUSTOMERS_BASE_URL} role="menuitem">
                          <FormattedMessage id="global_menu_resources_links_customers" />
                        </MenuLink>
                      </ListItem>
                    )}
                    {hasProductUpdatesPage && (
                      <ListItem>
                        <MenuLink to={PRODUCT_UPDATES_BASE_URL} role="menuitem">
                          <FormattedMessage id="global_menu_resources_links_productUpdates" />
                        </MenuLink>
                      </ListItem>
                    )}
                    {hasBlogPage && (
                      <ListItem>
                        <MenuLink to={BLOG_BASE_URL} role="menuitem">
                          <FormattedMessage id="global_menu_resources_links_blog" />
                        </MenuLink>
                      </ListItem>
                    )}
                    {hasPressPage && (
                      <ListItem>
                        <MenuLink to={PRESS_BASE_URL} role="menuitem">
                          <FormattedMessage id="global_menu_resources_links_press" />
                        </MenuLink>
                      </ListItem>
                    )}
                  </List>
                </MobileCollapsableMenu>
                <MobileCollapsableMenu
                  label={intl.formatMessage({
                    id: 'global_menu_company_title',
                  })}
                >
                  <List role="menu">
                    <ListItem>
                      <MenuLink to="/about-us/" role="menuitem">
                        <FormattedMessage id="global_menu_company_links_aboutUs" />
                      </MenuLink>
                    </ListItem>
                    {/* <ListItem>
                      <MenuLink to="/careers/" role="menuitem">
                        <FormattedMessage id="global_menu_company_links_careers" />
                      </MenuLink>
                    </ListItem> */}
                    <ListItem>
                      <MenuLink to="/contact-us/" role="menuitem">
                        <FormattedMessage id="global_menu_company_links_contact" />
                      </MenuLink>
                    </ListItem>
                  </List>
                </MobileCollapsableMenu>
              </MobileMenuContextProvider>
            </Content>
          </Container>
        )}
      </ReactPortal>
    </>
  )
}

export default HeaderMobileMenu

const en = {
  title: 'Join our customer-obsessed readers list.',
  text: 'Expertly curated content that’ll help you deliver an exceptional customer experience.',
  form: {
    input: {
      placeholder: 'Your email address',
    },
    button: 'Subscribe',
    successMessage: "You've been added successfully.",
  },
}

export default en

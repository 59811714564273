import { FunctionComponent } from 'react'
import styled from 'styled-components'
import pixelsToRems from '../../theme/utils/pixelsToRems'

const SVGContainer = styled.svg<Props>`
  height: ${pixelsToRems(32)};

  ${({ white }) =>
    white &&
    `
    path {
      fill: white;
    }
  `}
`

export interface Props {
  white?: boolean
}

const RoofLogo: FunctionComponent<Props> = ({ white }) => {
  return (
    <SVGContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 719 172"
      white={white}
    >
      <g>
      <path d="M505.545 23H409.167C388.814 23 372.246 39.6238 372.246 60.0454V110.931C372.246 131.352 388.814 147.976 409.167 147.976H505.545C525.899 147.976 542.467 131.352 542.467 110.931V60.0454C542.467 39.6238 525.899 23 505.545 23ZM504.104 116.607H410.622V54.2609H504.104V116.607Z" fill="#102040"/>
      <path d="M596.67 54.2744V85.6704H715.948V116.607H596.67V148.016H558.119V23.0135H719.181V54.2744H596.67Z" fill="#102040"/>
      <path d="M170.423 81.7781V46.1517C170.423 33.3662 160.092 23 147.349 23H131.832L0 23.0135V148.017H38.5514V116.607H131.832V147.976H170.437V118.823C170.437 108.592 162.166 100.294 151.969 100.294C162.166 100.294 170.437 91.9956 170.437 81.7645L170.423 81.7781ZM38.5514 54.2744H131.832V85.6705H38.5514V54.2744Z" fill="#102040"/>
      <path d="M319.47 23H223.091C202.738 23 186.17 39.6238 186.17 60.0454V110.931C186.17 131.352 202.738 147.976 223.091 147.976H319.47C339.823 147.976 356.391 131.352 356.391 110.931V60.0454C356.391 39.6238 339.836 23 319.47 23ZM318.028 116.607H224.546V54.2609H318.028V116.607Z" fill="#102040"/>
      </g>
    </SVGContainer>
  )
}

export default RoofLogo

import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import pixelsToRems from '../../theme/utils/pixelsToRems'

import defaultButtonStyle from './defaultButtonStyle'
import outlinedButtonStyle from './outlinedButtonStyle'
import ctaButtonStyle from './ctaButtonStyle'

export interface ContainerProps {
  size: ButtonSize
  variant: ButtonVariant
}

const Container = styled.button<ContainerProps>`
  display: inline-block;
  border-radius: ${pixelsToRems(25)};
  text-decoration: none;
  box-sizing: border-box;
  border: 0;

  ${({ size }) => {
    switch (size) {
      case ButtonSize.SM:
        return 'font-size: var(--font-size-small);'
      case ButtonSize.MD:
      default:
        return 'font-size: var(--font-size-medium);'
    }
  }}

  ${({ variant }) => {
    switch (variant) {
      case ButtonVariant.OUTLINED:
        return outlinedButtonStyle
      case ButtonVariant.CTA:
        return ctaButtonStyle
      case ButtonVariant.DEFAULT:
      default:
        return defaultButtonStyle
    }
  }}
`

export enum ButtonSize {
  SM,
  MD,
}

export enum ButtonVariant {
  DEFAULT,
  OUTLINED,
  CTA,
}

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize
  variant?: ButtonVariant
  className?: string
  children: ReactNode
}

const Button: FunctionComponent<Props> = ({
  className,
  type,
  size = ButtonSize.MD,
  variant = ButtonVariant.DEFAULT,
  children,
}) => {
  return (
    <Container
      type={type}
      tabIndex={0}
      size={size}
      variant={variant}
      className={className}
    >
      {children}
    </Container>
  )
}

export default Button

import { useState } from 'react'

export interface MobileMenuCollapsableSelectors {
  openMenu: string | null
}

export interface MobileMenuCollapsableActions {
  handleMenuToggle: (menuToOpen: string) => void
}

export interface MobileMenuCollapsableHook {
  selectors: MobileMenuCollapsableSelectors
  actions: MobileMenuCollapsableActions
}

function useMobileMenuCollapsable(): MobileMenuCollapsableHook {
  const [openMenu, setOpenMenu] = useState<string | null>(null)
  const handleMenuToggle = (menuToOpen: string) => {
    if (openMenu === menuToOpen) {
      setOpenMenu(null)
    } else {
      setOpenMenu(menuToOpen)
    }
  }

  return {
    selectors: {
      openMenu,
    },
    actions: {
      handleMenuToggle,
    },
  }
}

export default useMobileMenuCollapsable

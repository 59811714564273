import { useEffect, useState } from 'react'

export interface HeaderMobileMenuSelectors {
  open: boolean
  willClose: boolean
}

export interface HeaderMobileMenuActions {
  handleMenuOpen: () => void
  handleMenuClose: () => void
}

export interface HeaderMobileMenuHook {
  selectors: HeaderMobileMenuSelectors
  actions: HeaderMobileMenuActions
}

function useHeaderMobileMenu(): HeaderMobileMenuHook {
  const [open, setOpen] = useState(false)
  const [willClose, setWillClose] = useState(false)

  const handleMenuOpen = () => {
    setOpen(true)
  }
  const handleMenuClose = () => {
    setWillClose(true)
  }

  useEffect(() => {
    let debouncedClose: NodeJS.Timeout | null = null

    if (willClose === true) {
      debouncedClose = setTimeout(() => {
        setOpen(false)
        setWillClose(false)
      }, 400)
    }

    return () => {
      if (debouncedClose !== null) {
        clearTimeout(debouncedClose)
      }
    }
  }, [willClose])

  return {
    selectors: {
      open,
      willClose,
    },
    actions: {
      handleMenuOpen,
      handleMenuClose,
    },
  }
}

export default useHeaderMobileMenu

import { Link } from 'gatsby'
import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import useHasPages from '../../../hooks/useHasPages'

import { ThemeContainer } from '../../../theme/Theme'
import device, { size } from '../../../theme/utils/device'
import pixelsToRems from '../../../theme/utils/pixelsToRems'
import ButtonLink from '../../../_components/Button/ButtonLink'
import RoofLogo from '../../../_components/RoofLogo/RoofLogo'
import HeaderMenu from './HeaderMenu'
import HeaderMobileMenu from './HeaderMobileMenu'
import HeaderSubMenuLink from './HeaderSubMenuLink'

export const HEADER_HEIGHT = pixelsToRems(80)

const Container = styled.header`
  display: flex;
  height: ${HEADER_HEIGHT};
  background-color: #ffffff;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
`

const List = styled.ul`
  display: none;
  flex-direction: row;

  @media (min-width: ${size.lg}px) {
    display: flex;
  }

  li {
    margin-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
  }
`

const CTA = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  @media ${device.xsmall} {
    display: none;
  }
`

export const BLOG_BASE_URL = '/blog/'
export const PRODUCT_UPDATES_BASE_URL = '/updates/'
export const CUSTOMERS_BASE_URL = '/customers/'
export const PRESS_BASE_URL = '/press/'

const Header: FunctionComponent = () => {
  const {
    selectors: {
      hasBlogPage,
      hasProductUpdatesPage,
      hasCustomersPage,
      hasPressPage,
    },
  } = useHasPages()
  const intl = useIntl()

  return (
    <Container>
      <Content className="container">
        <Link to="/" aria-label="Roof logo, return to homepage">
          <RoofLogo />
        </Link>
        <Nav aria-labelledby="website-header">
          <List>
            <HeaderMenu
              label={intl.formatMessage({ id: 'global_menu_solutions_title' })}
            >
              <HeaderSubMenuLink
                to="/brokerage/"
                label={intl.formatMessage({
                  id: 'global_menu_solutions_links_brokerage',
                })}
              />
              <HeaderSubMenuLink
                to="/mortgage/"
                label={intl.formatMessage({
                  id: 'global_menu_solutions_links_mortgage',
                })}
              />
              <HeaderSubMenuLink
                to="/relocation/"
                label={intl.formatMessage({
                  id: 'global_menu_solutions_links_relocation',
                })}
              />
              <HeaderSubMenuLink
                to="/recruiting/"
                label={intl.formatMessage({
                  id: 'global_menu_solutions_links_recruiting',
                })}
              />
            </HeaderMenu>
            <HeaderMenu
              label={intl.formatMessage({ id: 'global_menu_resources_title' })}
            >
              {hasCustomersPage && (
                <HeaderSubMenuLink
                  to={CUSTOMERS_BASE_URL}
                  label={intl.formatMessage({
                    id: 'global_menu_resources_links_customers',
                  })}
                />
              )}
              {hasProductUpdatesPage && (
                <HeaderSubMenuLink
                  to={PRODUCT_UPDATES_BASE_URL}
                  label={intl.formatMessage({
                    id: 'global_menu_resources_links_productUpdates',
                  })}
                />
              )}
              {hasBlogPage && (
                <HeaderSubMenuLink
                  to={BLOG_BASE_URL}
                  label={intl.formatMessage({
                    id: 'global_menu_resources_links_blog',
                  })}
                />
              )}
              {hasPressPage && (
                <HeaderSubMenuLink
                  to={PRESS_BASE_URL}
                  label={intl.formatMessage({
                    id: 'global_menu_resources_links_press',
                  })}
                />
              )}
            </HeaderMenu>
            <HeaderMenu
              label={intl.formatMessage({ id: 'global_menu_company_title' })}
            >
              <HeaderSubMenuLink
                to="/about-us/"
                label={intl.formatMessage({
                  id: 'global_menu_company_links_aboutUs',
                })}
              />
              {/* <HeaderSubMenuLink
                to="/careers/"
                label={intl.formatMessage({
                  id: 'global_menu_company_links_careers',
                })}
              /> */}
              <HeaderSubMenuLink
                to="/contact-us/"
                label={intl.formatMessage({
                  id: 'global_menu_company_links_contact',
                })}
              />
            </HeaderMenu>
          </List>
          <CTA>
            <ButtonLink to="/contact-us/">
              {intl.formatMessage({
                id: 'global_menu_cta',
              })}
            </ButtonLink>
          </CTA>
          <HeaderMobileMenu />
        </Nav>
      </Content>
    </Container>
  )
}

export default Header

const en = {
  latestJobsTitle: 'Open Positions',
  otherJobsTitle: 'Future Positions',
  experienceYear:
    '({years} {years, plural, =0 {years} one {year} other {years}})',
  relatedPosts: { title: 'Related Jobs' },
  jobDetailsTitle: 'Job Details',
  salary: 'Salary',
  jobTypeTitle: 'Job Type',
  qualificationsTitle: 'Qualifications',
  jobType: {
    ['full-time']: 'Full-time',
    permanent: 'Permanent',
    contract: 'Contract',
    internship: 'internship',
    ['part-time']: 'Part-time',
    remote: 'Remote',
  },
}

export default en

import { PostClassification } from '../../../domain/resources/post'

const en = {
  learnMore: 'Learn more {arrow}',
  classifications: {
    [PostClassification.BLOG]: 'Blog',
    [PostClassification.CUSTOMERS]: 'Customers',
    [PostClassification.CAREERS]: 'Careers',
    [PostClassification.PRESS]: 'Press',
    [PostClassification.PRODUCT_UPDATES]: 'Product Updates',
  },
}

export default en

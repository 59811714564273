import { css } from 'styled-components'
import { ThemeContainer } from '../../theme/Theme'
import pixelsToRems from '../../theme/utils/pixelsToRems'

import { ContainerProps } from './Button'

const outlinedButtonStyle = css<ContainerProps>`
  padding: ${({ theme }: ThemeContainer) =>
    `${pixelsToRems(14)} ${theme.spacing.medium} ${pixelsToRems(10)};`}
  font-weight: var(--font-weight-bold);
  border: 2px solid var(--dark2);
  background-color: transparent;
  color: var(--dark2);

  &:hover, &.active {
    color: #fff;
    background-color: var(--dark2);
  }
`

export default outlinedButtonStyle

import { FunctionComponent } from 'react'
import styled from 'styled-components'

import pixelsToRems from '../../../theme/utils/pixelsToRems'

const SVGContainer = styled.svg`
  height: ${pixelsToRems(26)};
`

const Twitter: FunctionComponent = () => {
  return (
    <SVGContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.423 24.725"
    >
      <g transform="translate(-55.274 -92.868)">
        <g transform="translate(55.274 92.868)">
          <path
            fill="#fff"
            d="M64.842 117.593c11.481 0 17.759-9.512 17.759-17.759 0-.27-.005-.54-.018-.807A12.706 12.706 0 0085.7 95.8a12.462 12.462 0 01-3.584.983 6.263 6.263 0 002.744-3.453 12.511 12.511 0 01-3.963 1.515 6.247 6.247 0 00-10.637 5.693 17.721 17.721 0 01-12.866-6.522 6.247 6.247 0 001.932 8.333 6.194 6.194 0 01-2.827-.781v.08a6.244 6.244 0 005.008 6.119 6.232 6.232 0 01-2.819.107 6.249 6.249 0 005.831 4.335 12.526 12.526 0 01-7.752 2.672 12.727 12.727 0 01-1.489-.086 17.671 17.671 0 009.568 2.8"
            transform="translate(-55.274 -92.868)"
          />
        </g>
      </g>
    </SVGContainer>
  )
}

export default Twitter

import { Link, graphql, useStaticQuery } from 'gatsby'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import useHasPages from '../../hooks/useHasPages'

import { ThemeContainer } from '../../theme/Theme'
import device from '../../theme/utils/device'
import RoofLogo from '../../_components/RoofLogo/RoofLogo'
import {
  BLOG_BASE_URL,
  CUSTOMERS_BASE_URL,
  PRESS_BASE_URL,
  PRODUCT_UPDATES_BASE_URL,
} from '../Header/components/Header'
import SocialLink, { SocialIcon } from '../../_components/SocialLink/SocialLink'
import pixelsToRems from '../../theme/utils/pixelsToRems'

const Container = styled.footer``

const TopContainer = styled.div`
  background-color: var(--dark2);
  padding-top: ${pixelsToRems(72)};
  padding-bottom: ${pixelsToRems(72)};
`

const BottomContainer = styled.div`
  background-color: var(--dark2);
  padding-top: ${({ theme }: ThemeContainer) => theme.spacing.xsmall};
  padding-bottom: ${({ theme }: ThemeContainer) => theme.spacing.small};

  .container {
    justify-content: space-between;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;

  @media ${device.small} {
    flex-direction: column;
  }

  @media ${device.xsmall} {
    flex-direction: column;
  }
`

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &.logoSection {
    flex: 2;
  }

  @media ${device.xsmall} {
    &.logoSection {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.xlarge};
    }
  }
  @media ${device.small} {
    &.logoSection {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.xlarge};
    }
  }
  @media ${device.medium} {
    &:not(:last-child) {
      margin-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    }
  }

  @media ${device.large} {
    &:not(:last-child) {
      margin-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    }
  }

  @media ${device.xlarge} {
    &:not(:last-child) {
      margin-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    }
  }
`

const MenuLabel = styled.div`
  color white;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.medium};

  @media ${device.xsmall} {
    font-size: var(--font-size-large);
  }

  @media ${device.small} {
    font-size: var(--font-size-large);
  }
`

const List = styled.ul`
  li:not(:last-child) {
    margin-bottom: ${pixelsToRems(19.2)};
  }

  @media ${device.xsmall} {
    margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.xlarge};

    li:not(:last-child) {
      margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    }
  }

  @media ${device.small} {
    margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.xlarge};

    li:not(:last-child) {
      margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    }
  }
`

const ListItem = styled.li`
  a {
    color: white;
    text-decoration: none;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-light);

    &:hover {
      color: var(--blue3);
    }

    @media ${device.xsmall} {
      font-size: var(--font-size-large);
    }

    @media ${device.small} {
      font-size: var(--font-size-large);
    }
  }
`

const Copyrights = styled.span`
  color: white;
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.xsmall};
  font-weight: var(--font-weight-light);
  align-content: bottom;

  @media ${device.xsmall} {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.small};

    span {
      display: block;
    }
  }

  @media ${device.small} {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: ${({ theme }: ThemeContainer) => theme.spacing.small};

    span {
      display: block;
    }
  }
`

const SocialLinks = styled.div`
  a {
    svg path {
      fill: #fff;
    }

    &:not(:last-child) {
      margin-right: ${({ theme }: ThemeContainer) => theme.spacing.small};
    }
  }

  @media ${device.xsmall} {
    margin-top: 0;
    text-align: center;
  }

  @media ${device.small} {
    margin-top: 0;
    text-align: center;
  }
`

interface LegalPagesNodeData {
  id: string
  frontmatter: {
    slug: string
    title: string
  }
}

interface LegalPagesQueryData {
  allMdx: {
    nodes: LegalPagesNodeData[]
  }
}

const legalPagesQuery = graphql`
  query LegalPagesLinks {
    allMdx(
      filter: {
        frontmatter: {
          templateKey: { eq: "legal" }
          showInFooter: { eq: true }
        }
      }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
        }
      }
    }
  }
`

const Footer: FunctionComponent = () => {
  const {
    allMdx: { nodes },
  } = useStaticQuery<LegalPagesQueryData>(legalPagesQuery)
  const {
    selectors: {
      hasBlogPage,
      hasProductUpdatesPage,
      hasCustomersPage,
      hasPressPage,
    },
  } = useHasPages()
  const currentDate = new Date()

  return (
    <Container>
      <TopContainer>
        <Content className="container">
          <MenuContent className="logoSection">
            <Link
              to="/"
              className="logo"
              aria-label="Roof logo, return to homepage"
            >
              <RoofLogo white />
            </Link>
          </MenuContent>
          <MenuContent>
            <MenuLabel>
              <FormattedMessage id="global_menu_solutions_title" />
            </MenuLabel>
            <List>
              <ListItem>
                <Link to="/brokerage/">
                  <FormattedMessage id="global_menu_solutions_links_brokerage" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/mortgage/">
                  <FormattedMessage id="global_menu_solutions_links_mortgage" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/relocation/">
                  <FormattedMessage id="global_menu_solutions_links_relocation" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/recruiting/">
                  <FormattedMessage id="global_menu_solutions_links_recruiting" />
                </Link>
              </ListItem>
            </List>
          </MenuContent>
          <MenuContent>
            <MenuLabel>
              <FormattedMessage id="global_menu_resources_title" />
            </MenuLabel>
            <List>
              {hasCustomersPage && (
                <ListItem>
                  <Link to={CUSTOMERS_BASE_URL}>
                    <FormattedMessage id="global_menu_resources_links_customers" />
                  </Link>
                </ListItem>
              )}
              {hasProductUpdatesPage && (
                <ListItem>
                  <Link to={PRODUCT_UPDATES_BASE_URL}>
                    <FormattedMessage id="global_menu_resources_links_productUpdates" />
                  </Link>
                </ListItem>
              )}
              {hasBlogPage && (
                <ListItem>
                  <Link to={BLOG_BASE_URL}>
                    <FormattedMessage id="global_menu_resources_links_blog" />
                  </Link>
                </ListItem>
              )}
              {hasPressPage && (
                <ListItem>
                  <Link to={PRESS_BASE_URL}>
                    <FormattedMessage id="global_menu_resources_links_press" />
                  </Link>
                </ListItem>
              )}
            </List>
          </MenuContent>
          <MenuContent>
            <MenuLabel>
              <FormattedMessage id="global_menu_company_title" />
            </MenuLabel>
            <List>
              <ListItem>
                <Link to="/about-us/">
                  <FormattedMessage id="global_menu_company_links_aboutUs" />
                </Link>
              </ListItem>
              {/* <ListItem>
                <Link to="/careers/">
                  <FormattedMessage id="global_menu_company_links_careers" />
                </Link>
              </ListItem> */}
              <ListItem>
                <Link to="/contact-us/">
                  <FormattedMessage id="global_menu_company_links_contact" />
                </Link>
              </ListItem>
              {nodes.map(({ id, frontmatter: { slug, title } }) => (
                <ListItem key={id}>
                  <Link to={`/${slug}/`}>{title}</Link>
                </ListItem>
              ))}
            </List>
          </MenuContent>
        </Content>
      </TopContainer>
      <BottomContainer>
        <Content className="container">
          <Copyrights>
            <span>© {currentDate.getFullYear()} Roof AI Inc.</span>
          </Copyrights>
          <SocialLinks>
            <SocialLink
              href="https://www.linkedin.com/company/roof-ai/"
              media={SocialIcon.LINKEDIN}
              ariaLabel="LinkedIn account"
            />
            <SocialLink
              href="https://twitter.com/roof_ai"
              media={SocialIcon.TWITTER}
              ariaLabel="Twitter account"
            />
          </SocialLinks>
        </Content>
      </BottomContainer>
    </Container>
  )
}

export default Footer

import {
  useHover,
  useInteractions,
  useFloating,
  FloatingPortal,
  FloatingFocusManager,
  safePolygon,
  useRole,
  useClick,
  useFloatingTree,
  useDismiss,
} from '@floating-ui/react-dom-interactions'
import {
  Children,
  cloneElement,
  forwardRef,
  FunctionComponent,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'

import HeaderMenuButton from './HeaderMenuButton'
import { useLocationContext } from '../../Layout/Layout'
import { mergeRefs } from '../../../utils/mergeRefs'
import { ThemeContainer } from '../../../theme/Theme'

const Container = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const SubMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  z-index: 2;
`

const SubmenuTitle = styled.div`
  display: none;
  min-width: 160px;
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.large};
  font-weight: var(--font-weight-bold);
  padding: ${({ theme }: ThemeContainer) =>
    `${theme.spacing.medium} 0 ${theme.spacing.small}`};
  margin: ${({ theme }: ThemeContainer) =>
    `0 ${theme.spacing.medium} ${theme.spacing.small}`};
  border-bottom: 1px solid var(--text);
`

export interface Props {
  label: string
  to?: string
  children?: ReactNode
}

const HeaderMenu: FunctionComponent<Props> = forwardRef(
  ({ label, children, to }, ref) => {
    const { pathname } = useLocationContext()
    const [open, setOpen] = useState<boolean>(false)
    const tree = useFloatingTree()
    const { x, y, reference, floating, strategy, context } =
      useFloating<HTMLDivElement>({
        open,
        onOpenChange: setOpen,
        placement: 'bottom-start',
      })
    const hover = useHover(context, {
      mouseOnly: true,
      handleClose: safePolygon({ restMs: 25 }),
    })
    const role = useRole(context, { role: 'menu' })
    const click = useClick(context, {
      ignoreMouse: true,
    })
    const dismiss = useDismiss(context, {
      referencePress: false,
    })
    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, role, click, dismiss])

    const mergedReferenceRef = useMemo(
      () => mergeRefs([ref, reference]),
      [reference, ref]
    )

    const isActive: boolean = useMemo(
      () =>
        Children.map(
          children,
          (child) => isValidElement(child) && pathname.includes(child.props.to)
        )?.find(Boolean) || false,
      [children, pathname]
    )

    useEffect(() => {
      if (open) {
        setOpen(false)
      }
    }, [pathname])

    return (
      <Container>
        <HeaderMenuButton
          ref={mergedReferenceRef}
          label={label}
          open={open}
          isActive={isActive}
          referenceProps={getReferenceProps({
            // for a native <button>
            onClick() {
              setOpen(false)
            },
          })}
          hasChildren={Children.count(children) > 0}
          to={to}
        />
        {Children.count(children) > 0 && (
          <FloatingPortal>
            {open && (
              <FloatingFocusManager
                context={context}
                returnFocus
                order={['reference', 'content']}
              >
                <SubMenu
                  ref={floating}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  }}
                  {...getFloatingProps()}
                >
                  <SubmenuTitle>{label}</SubmenuTitle>
                  {Children.map(
                    children,
                    (child) =>
                      isValidElement(child) &&
                      cloneElement(
                        child,
                        getItemProps({
                          tabIndex: -1,
                          role: 'menuitem',
                          onClick() {
                            tree?.events.emit('click')
                          },
                        })
                      )
                  )}
                </SubMenu>
              </FloatingFocusManager>
            )}
          </FloatingPortal>
        )}
      </Container>
    )
  }
)

HeaderMenu.displayName = 'HeaderMenu'

export default HeaderMenu

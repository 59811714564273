import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from 'react'
import useMobileMenuCollapsable from '../hooks/useMobileMenuCollapsable'

interface MobileMenuContextInterface {
  openMenu: string | null
  handleMenuToggle: (menuToOpen: string) => void
}

const MobileMenuContext = createContext<MobileMenuContextInterface | null>(null)

export const useMobileMenu = (): MobileMenuContextInterface => {
  const context = useContext(MobileMenuContext)

  if (context === undefined) {
    throw new Error('useMobileMenu must be used within MobileMenuContext')
  }

  return context as MobileMenuContextInterface
}

export interface Props {
  children: ReactNode
}

const MobileMenuContextProvider: FunctionComponent<Props> = ({ children }) => {
  const {
    selectors: { openMenu },
    actions: { handleMenuToggle },
  } = useMobileMenuCollapsable()

  return (
    <MobileMenuContext.Provider value={{ openMenu, handleMenuToggle }}>
      {children}
    </MobileMenuContext.Provider>
  )
}

export default MobileMenuContextProvider

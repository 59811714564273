import { Link } from 'gatsby'
import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { ButtonSize, ButtonVariant } from './Button'
import ctaButtonStyle from './ctaButtonStyle'

import defaultButtonStyle from './defaultButtonStyle'
import outlinedButtonStyle from './outlinedButtonStyle'

export interface ContainerProps {
  size: ButtonSize
  variant: ButtonVariant
}

const Container = styled(Link)<ContainerProps>`
  display: inline-block;
  border-radius: 25px;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;

  ${({ size }) => {
    switch (size) {
      case ButtonSize.SM:
        return 'font-size: var(--font-size-small);'
      case ButtonSize.MD:
      default:
        return 'font-size: var(--font-size-medium);'
    }
  }}

  ${({ variant }) => {
    switch (variant) {
      case ButtonVariant.OUTLINED:
        return outlinedButtonStyle
      case ButtonVariant.CTA:
        return ctaButtonStyle
      case ButtonVariant.DEFAULT:
      default:
        return defaultButtonStyle
    }
  }}
`

export interface Props {
  size?: ButtonSize
  variant?: ButtonVariant
  className?: string
  to?: string
  partiallyActive?: boolean
  children: ReactNode
}

const ButtonLink: FunctionComponent<Props> = ({
  className,
  size = ButtonSize.MD,
  variant = ButtonVariant.DEFAULT,
  to = '/',
  partiallyActive,
  children,
}) => {
  return (
    <Container
      tabIndex={0}
      to={to}
      size={size}
      variant={variant}
      activeClassName="active"
      className={className}
      partiallyActive={partiallyActive}
    >
      {children}
    </Container>
  )
}

export default ButtonLink

import { IGatsbyImageData } from 'gatsby-plugin-image'
import { SEOEntry } from '../../_components/SEO/SEO'

export enum PostClassification {
  BLOG = 'blog',
  PRODUCT_UPDATES = 'updates',
  CUSTOMERS = 'customers',
  PRESS = 'press',
  CAREERS = 'careers',
}

export interface PostAuthor {
  name: string
  employeePhoto: string
}

export interface PostExcerpt {
  frontmatter: {
    slug: string
    title: string
    summary: string
    category: PostClassification
    published_date: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
  }
  excerpt: string
  id: string
}

export interface Post {
  frontmatter: {
    slug: string
    title: string
    published_date: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
    author: PostAuthor
    relatedPosts: PostExcerpt[]
    seo: SEOEntry
  }
  body: string
  excerpt: string
}

export interface CaseStudyMetric {
  metric: string
  title: string
}

export interface CaseStudy {
  frontmatter: {
    slug: string
    title: string
    text: string
    published_date: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
    metrics: CaseStudyMetric[]
    seo: SEOEntry
  }
  body: string
  excerpt: string
}

export interface ProductUpdate {
  frontmatter: {
    slug: string
    title: string
    published_date: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
    author: PostAuthor
    seo: SEOEntry
  }
  body: string
  excerpt: string
}

export interface CareersPostExcerpt {
  frontmatter: {
    slug: string
    title: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
  }
}

export interface CareersSalary {
  min: number
  max: number | null
}

export interface CareersQualification {
  title: string
  experience: number | null
}

export interface CareerPost {
  id: string
  frontmatter: {
    title: string
    photo: {
      childImageSharp: IGatsbyImageData
    }
    slug: string
    published_date: string
    salary: CareersSalary
    jobType: string[]
    qualifications: CareersQualification[]
    relatedPosts: PostExcerpt[]
    seo: SEOEntry
  }
  body: string
}

export interface PostData {
  mdx: Post
}

export interface CaseStudyData {
  mdx: CaseStudy
}

export interface ProductUpdateData {
  mdx: ProductUpdate
}

export interface CareerPostData {
  mdx: CareerPost
}

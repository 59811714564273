import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { ThemeContainer } from '../../theme/Theme'
import device from '../../theme/utils/device'
import Twitter from '../icons/Twitter/Twitter'
import LinkedIn from '../icons/LinkedIn/LinkedIn'
import EmailIcon from '../icons/Email/EmailIcon'

const Container = styled.a`
  display: inline-flex;
  align-items: flex-end;
  text-decoration: none;
  align-items: baseline;

  svg {
    height: 16px;

    path {
      fill: var(--text);
    }
  }

  &:hover {
    &.linkedin {
      color: ${({ theme }: ThemeContainer) => theme.colors.linkedin};

      svg path {
        fill: ${({ theme }: ThemeContainer) => theme.colors.linkedin};
      }
    }
    &.twitter {
      color: ${({ theme }: ThemeContainer) => theme.colors.twitter};

      svg path {
        fill: ${({ theme }: ThemeContainer) => theme.colors.twitter};
      }
    }
    &.email {
      color: ${({ theme }: ThemeContainer) => theme.colors.red};

      svg path {
        fill: ${({ theme }: ThemeContainer) => theme.colors.red};
      }
    }
  }

  @media ${device.xsmall} {
    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
  @media ${device.small} {
    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
`

export enum SocialIcon {
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  EMAIL = 'email',
}

export const renderSocialIcon = (icon: SocialIcon) => {
  switch (icon) {
    case SocialIcon.TWITTER:
      return <Twitter />
    case SocialIcon.LINKEDIN:
      return <LinkedIn />
    case SocialIcon.EMAIL:
      return <EmailIcon />
    default:
      return null
  }
}

export interface Props {
  href: string
  media: SocialIcon
  ariaLabel: string
}

const SocialLink: FunctionComponent<Props> = ({ media, href, ariaLabel }) => {
  return (
    <Container
      href={href}
      target="_blank"
      rel="nofollow noopener"
      className={media}
      aria-label={ariaLabel}
    >
      {renderSocialIcon(media)}
    </Container>
  )
}

export default SocialLink

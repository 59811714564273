import { graphql, useStaticQuery } from 'gatsby'

const queryHasPages = graphql`
  query {
    hasBlogPage: sitePage(path: { eq: "/blog/" }) {
      path
    }
    hasProductUpdatesPage: sitePage(path: { eq: "/updates/" }) {
      path
    }
    hasCustomersPage: sitePage(path: { eq: "/customers/" }) {
      path
    }
    hasPressPage: sitePage(path: { eq: "/press/" }) {
      path
    }
  }
`

export interface HasPagesSelectors {
  hasBlogPage: boolean
  hasProductUpdatesPage: boolean
  hasCustomersPage: boolean
  hasPressPage: boolean
}

export interface HasPagesHook {
  selectors: HasPagesSelectors
}

function useHasPages(): HasPagesHook {
  const { hasBlogPage, hasProductUpdatesPage, hasCustomersPage, hasPressPage } =
    useStaticQuery(queryHasPages)

  return {
    selectors: {
      hasBlogPage: hasBlogPage !== null,
      hasProductUpdatesPage: hasProductUpdatesPage !== null,
      hasCustomersPage: hasCustomersPage !== null,
      hasPressPage: hasPressPage !== null,
    },
  }
}

export default useHasPages

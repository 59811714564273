import { FunctionComponent } from 'react'
import styled from 'styled-components'

import pixelsToRems from '../../../theme/utils/pixelsToRems'

const SVGContainer = styled.svg`
  height: ${pixelsToRems(26)};
`

const LinkedIn: FunctionComponent = () => {
  return (
    <SVGContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.598 26.552"
    >
      <g transform="translate(-366.292 -366.771)">
        <path
          fill="#fff"
          d="M392.888 393.323h-5.507V384.7c0-2.057-.037-4.7-2.864-4.7-2.868 0-3.307 2.241-3.307 4.554v8.773h-5.51v-17.74h5.287v2.424h.074a5.794 5.794 0 015.216-2.865c5.582 0 6.611 3.671 6.611 8.448zm-23.4-20.16a3.2 3.2 0 113.2-3.2 3.2 3.2 0 01-3.2 3.2m2.754 20.16h-5.513v-17.736h5.513z"
        />
      </g>
    </SVGContainer>
  )
}

export default LinkedIn

import { css } from 'styled-components'
import { ThemeContainer } from '../../theme/Theme'
import pixelsToRems from '../../theme/utils/pixelsToRems'

import { ContainerProps } from './Button'

const ctaButtonStyle = css<ContainerProps>`
  padding: ${({ theme }: ThemeContainer) =>
    `${pixelsToRems(22)} ${theme.spacing.large} ${pixelsToRems(20)};`}
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  background-color: var(--blue5);
  border-radius: ${pixelsToRems(30)};
  color: #ffffff;

  &:hover, &.active {
    background-color: var(--blue1);
  }
`

export default ctaButtonStyle

import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import styled from 'styled-components'

import { ThemeContainer } from '../../../theme/Theme'
import Chevron from '../../../_components/icons/Chevron/Chevron'

type StyledMenuType = HTMLProps<HTMLDivElement> & { isActive: boolean }

export const Menu = styled.div<StyledMenuType>`
  display: flex;
  position: relative;
  flex: 1;
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.medium};
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  & > span,
  & > a {
    display: flex;
    flex: 1;
    align-items: center;
    text-decoration: none;
    color: var(--text);
    height: 80px;

    &:focus-visible {
      outline: none;
    }
  }

  & > a {
    cursor: pointer;
  }

  svg {
    margin-left: ${({ theme }: ThemeContainer) => theme.spacing.small};
    transition: transform 0.2s var(--ease-in-quint);

    ${({ open }) => open && 'transform: scale(-1);'}
  }

  .hover {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 0%;
    height: 3px;
    background-color: var(--blue5);
    border-radius: 2px;

    transition-property: left, width;
    transition-duration: 0.3s;
    transition-timing-function: var(--ease-in-out-quint);
  }

  &:hover {
    & > .hover {
      width: 100%;
      left: 0%;
    }
  }

  ${({ open, isActive }) =>
    (open || isActive) &&
    `
    & > .hover {
      width: 100%;
      left: 0%;
    }
  `}
`

export interface Props {
  ref: ForwardedRef<HTMLDivElement> | undefined
  label: string
  open?: boolean
  isActive: boolean
  referenceProps: Record<string, unknown>
  hasChildren: boolean
  to?: string
}

const HeaderMenuButton = forwardRef<HTMLDivElement, Props>(
  ({ label, open, isActive, referenceProps, hasChildren }, ref) => {
    return (
      <Menu
        ref={ref}
        tabIndex={0}
        {...referenceProps}
        role="menuitem"
        open={open}
        isActive={isActive}
        aria-haspopup="true"
        aria-expanded={open}
      >
        <span>
          {label} {hasChildren && <Chevron />}
        </span>
        <div className="hover" />
      </Menu>
    )
  }
)

HeaderMenuButton.displayName = 'HeaderMenuButton'

export default HeaderMenuButton

const en = {
  title: 'Get your AI-powered sales assistant',
  text: 'Your privacy is important to us.',
  form: {
    inputs: {
      fullname: {
        placeholder: 'Full name',
      },
      email: {
        placeholder: 'Email',
      },
      phone: {
        placeholder: 'Phone',
      },
      company: {
        placeholder: 'Company',
      },
      jobTitle: {
        placeholder: 'Job title',
      },
    },
    button: 'Book your demo',
    buttonUrl: '/contact-us/',
    successMessage:
      "You've been added successfully. Our team will get back to you shortly.",
  },
}

export default en

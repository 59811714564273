import { Link } from 'gatsby'
import { forwardRef, FunctionComponent } from 'react'
import styled from 'styled-components'
import { ThemeContainer } from '../../../theme/Theme'

const StyledLink = styled(Link)`
  display: block;
  padding: 8px;
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.medium};
  color: var(--text);
  padding: ${({ theme }: ThemeContainer) =>
    `${theme.spacing.small} ${theme.spacing.medium}`};
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: var(--grey2);
  }

  &:focus-visible {
    outline-width: 0;
  }
`

export interface Props {
  to: string
  label: string
}

const HeaderSubMenuLink: FunctionComponent<Props> = forwardRef<
  Link<{ active: boolean }>,
  Props
>(({ to, label }, ref) => {
  return (
    <StyledLink to={to} ref={ref}>
      {label}
    </StyledLink>
  )
})

HeaderSubMenuLink.displayName = 'HeaderSubMenuLink'

export default HeaderSubMenuLink

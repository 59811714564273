const en = {
  menu: {
    solutions: {
      title: 'Solutions',
      links: {
        brokerage: 'Brokerage',
        mortgage: 'Mortgage',
        recruiting: 'Recruiting',
        relocation: 'Relocation',
      },
    },
    resources: {
      title: 'Resources',
      links: {
        blog: 'Blog',
        productUpdates: "What's new",
        customers: 'Customers',
        press: 'Press',
      },
    },
    company: {
      title: 'Company',
      links: {
        aboutUs: 'About us',
        careers: 'Careers',
        contact: 'Contact us',
      },
    },
    cta: 'Contact Sales',
  },
  readMore: 'Read more',
}

export default en
